import React from "react";
import firebase from "firebase";
import firebaseConfig from "./firebaseConfig";
import PostCard from "./PostCard";
import { Scrollbars } from "react-custom-scrollbars";
import DeleteModal from "./DeleteModal";
class ManagePosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      showModal: false,
      toDelete: undefined,
      confirmedDelete: false,
    };
  }
  getPosts = () => {
    const db = firebase.firestore();
    const postsRef = db.collection("posts");

    postsRef.orderBy("timestamp", "desc").onSnapshot((snapshot) => {
      let newPosts = [];
      snapshot.docs.forEach((doc) => {
        newPosts.push(doc);
      });
      this.setState({ posts: newPosts });
    });
  };

  componentDidMount() {
    const firebaseApp =
      firebase.apps && firebase.apps.length > 0
        ? firebase.apps[0]
        : firebase.initializeApp(firebaseConfig);
    this.getPosts();
  }

  editPost = (event) => {
    const edit = this.props.edit;
    const target = event.target;
    console.log(target);
    const db = firebase.firestore();
    const docID = target.getAttribute("eyeDee");
    console.log(docID);
    db.collection("posts")
      .doc(docID)
      .get()
      .then(function (doc) {
        edit(doc);
      });
  };

  deletePost = (id) => {
    const db = firebase.firestore();

    console.log(id);
    if (this.state.confirmedDelete) {
      console.log("deleting!");
      db.collection("posts")
        .doc(id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error removing document: ", error);
        });
      this.setState(
        { confirmedDelete: false, toDelete: undefined, showModal: false },
        () => console.log(this.state)
      );
    } else {
      this.setState({ showModal: true, toDelete: id }, () =>
        console.log("toDelete", this.state.toDelete)
      );
    }
  };
  render() {
    const posts = this.state.posts;
    const postCards = posts.map((doc) => (
      <PostCard
        eyeDee={doc.id}
        title={doc.data().title}
        img={doc.data().imgs[0]}
        edit={this.editPost}
        delete={() => this.deletePost(doc.id)}
      />
    ));
    return (
      <>
        <h2>Manage Posts</h2>
        <Scrollbars style={{ width: "19rem", maxHeight: "610px" }}>
          {postCards}
        </Scrollbars>
        <DeleteModal
          show={this.state.showModal}
          confirmDelete={() => {
            this.setState({ confirmedDelete: true }, () => {
              console.log("about to delete!");
              console.log(this.state.confirmedDelete);
              this.deletePost(this.state.toDelete);
            });
          }}
          handleClose={() => this.setState({ showModal: false })}
        />
      </>
    );
  }
}

export default ManagePosts;
