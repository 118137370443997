const firebaseConfig = {
    apiKey: "AIzaSyBcuFeOb1Ju1hMp0oj66iQEvBKPkkUTH6s",
    authDomain: "food-blog-cb520.firebaseapp.com",
    databaseURL: "https://food-blog-cb520.firebaseio.com",
    projectId: "food-blog-cb520",
    storageBucket: "food-blog-cb520.appspot.com",
    messagingSenderId: "733038112282",
    appId: "1:733038112282:web:e30644ce53ad0fe12a6ba3",
    measurementId: "G-YJGQ1MKL00"
  };

  export default firebaseConfig