import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";

function BarOfNav(props) {
  return (
    <>
      <Navbar bg="dark" variant="dark" style={{ marginBottom: "2rem" }}>
        <Navbar.Brand href="#home">Three Birds Kitchen</Navbar.Brand>
        <Nav className="mr-auto"></Nav>
        {props.user ? (
          <>
            <Button variant="outline-danger" onClick={props.signOut}>
              Sign out
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={props.signInWithGoogle}>
            Sign in with Google
          </Button>
        )}
      </Navbar>
    </>
  );
}
export default BarOfNav;
