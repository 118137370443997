import React from "react";

import "./App.css";
import withFirebaseAuth from "react-with-firebase-auth";
import * as firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "./firebaseConfig";
import AddPost from "./AddPost";
import BarOfNav from "./BarOfNav";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ManagePosts from "./ManagePosts";

const firebaseApp = firebase.initializeApp(firebaseConfig);

class App extends React.Component {
  state = {
    editDoc: undefined,
  };

  edit = (doc) => {
    console.log("editing!");
    this.setState({ editDoc: doc });
    this.render();
  };

  delete = () => {
    this.setState({ showModal: true });
  };
  confirmDelete = (doc) => {
    this.setState({ confirmedDelete: doc.id });
  };
  render() {
    const { user, signOut, signInWithGoogle } = this.props;
    console.log("rendered");
    return (
      <div className="App">
        <BarOfNav
          user={this.props.user}
          signInWithGoogle={this.props.signInWithGoogle}
          signOut={this.props.signOut}
        />
        <Container fluid style={{ display: "block", margin: "auto" }}>
          <Row style={{ justifyContent: "space-between" }}>
            <Col md={{ span: 7, offset: 2 }}>
              <AddPost editDoc={this.state.editDoc} />
            </Col>
            <Col md="auto">
              <ManagePosts
                edit={this.edit}
                delete={this.delete}
                confirmedDelete={this.state.confirmedDelete}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const firebaseAppAuth = firebaseApp.auth();

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
