import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const PostCard = (props) => {
  const buttonStyle = { margin: "0.5rem" };
  return (
    <Card style={{ width: "18rem", margin: "0.5rem" }}>
      <Card.Img variant="top" src={props.img} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Button
          style={buttonStyle}
          variant="secondary"
          eyeDee={props.eyeDee}
          onClick={props.edit}
        >
          Edit Post
        </Button>
        <Button
          style={buttonStyle}
          variant="danger"
          eyeDee={props.eyeDee}
          onClick={props.delete}
        >
          Delete Post
        </Button>
      </Card.Body>
    </Card>
  );
};

export default PostCard;
